import { HeadstarttOutlineButton } from "../headstarttButton";
import { Filter } from "../icons";

const ShowMoreFiltersCTA = () => {
  return (
    <HeadstarttOutlineButton
      onClick={() => {}}
      style={{
        border: "1px solid #006653",
        width: "100%",
        borderRadius: "8px",
        padding: "8px",
        height: "fit-content",
      }}
    >
      <div className="flex items-center gap-2">
        <Filter height={18} className="text-primary" />
        <span className="fs-14 font-medium text-primary">
          show more filters...
        </span>
      </div>
    </HeadstarttOutlineButton>
  );
};

export default ShowMoreFiltersCTA;
