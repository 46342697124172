import FilterHeader from "./filterHeader";
import { HeadstarttCheckbox } from "../headstarttCheckbox";
const CATEGORIES = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Biking",
    value: "biking",
  },
  {
    label: "Camping",
    value: "camping",
  },
  {
    label: "Family",
    value: "family",
  },
  {
    label: "Skiing",
    value: "skiing",
  },
];
const CategoryFilter = () => {
  return (
    <div>
      <FilterHeader heading={"Category"} handleClear={() => {}} />
      {CATEGORIES.map((category) => (
        <div key={category.value} className="space-y-2">
          <HeadstarttCheckbox>
            <span className="fs-14 font-medium text-muted">
              {category.label}
            </span>
          </HeadstarttCheckbox>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
