import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FilterDifficulty } from "core/repository/search/types";
import FilterHeader from "./filterHeader";
import { HeadstarttCheckbox } from "../headstarttCheckbox";

const DIFFICULTY_TYPES = [
  {
    label: "Easy",
    value: FilterDifficulty.EASY,
  },
  {
    label: "Moderate",
    value: FilterDifficulty.MODERATE,
  },
  {
    label: "Difficult",
    value: FilterDifficulty.DIFFICULT,
  },
];
const DifficultyFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const trailDifficulties = searchParams.get("trailDifficulties");
  const [selectedDifficulty, setSelectedDifficulty] = useState<
    FilterDifficulty[]
  >(
    trailDifficulties
      ? trailDifficulties.split(",").map((item) => item as FilterDifficulty)
      : []
  );

  useEffect(() => {
    if (selectedDifficulty.length > 0) {
      searchParams.set("trailDifficulties", selectedDifficulty.join(","));
    } else {
      searchParams.delete("trailDifficulties");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [selectedDifficulty, location.pathname, navigate, searchParams]);

  return (
    <div>
      <FilterHeader
        heading={"Difficulty"}
        handleClear={() => {
          setSelectedDifficulty([]);
        }}
      />
      {DIFFICULTY_TYPES.map((item) => (
        <div key={item.value} className="space-y-2">
          <div className="flex gap-2">
            <HeadstarttCheckbox
              checked={selectedDifficulty.includes(item.value)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedDifficulty([...selectedDifficulty, item.value]);
                } else {
                  setSelectedDifficulty(
                    selectedDifficulty.filter(
                      (difficulty) => difficulty !== item.value
                    )
                  );
                }
              }}
            >
              <span className="fs-14 text-muted font-medium">{item.label}</span>
            </HeadstarttCheckbox>
            {/* <HeadstarttTooltip title="prompt text">
              <Indicator />
            </HeadstarttTooltip> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DifficultyFilter;
