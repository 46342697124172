import { ExploreHeading } from "views/components/typography/popularHeading";
import { HeadstarttBreadcrumb } from "views/components/headstarttBreadcrumbs";
import HeadstarttSegmentMenu from "views/components/headstarttSegmentMenu";
import { Link } from "react-router-dom";

type ExploreCoverProps = {
  path: string;
  search: string;
};
const ExploreCover = ({ search, path }: ExploreCoverProps) => {
  const firstString = search?.split(" ")[0] ?? "";
  const remainingString = search?.replace(firstString, "") ?? "";
  return (
    <div>
      <HeadstarttBreadcrumb separator={">"}>
        <HeadstarttBreadcrumb.Item>
          <Link to="/">Home</Link>
        </HeadstarttBreadcrumb.Item>
        <HeadstarttBreadcrumb.Item>
          <Link to="/explore/all">Search</Link>
        </HeadstarttBreadcrumb.Item>
      </HeadstarttBreadcrumb>
      <ExploreHeading
        headingPartOne={firstString}
        headingPartTwo={remainingString}
      />
      <HeadstarttSegmentMenu
        selected={`/explore/${path}`}
        segmentMenu={[
          {
            title: "All",
            path: "/explore/all",
          },
          {
            title: "Destination",
            path: "/explore/destination",
          },
          {
            title: "Activities",
            path: "/explore/activities",
          },
          {
            title: "Events",
            path: "/explore/events",
          },
          {
            title: "Projects",
            path: "/explore/projects",
          },
        ]}
      />
    </div>
  );
};

export default ExploreCover;
