import LoginWithEmailButton from "./components/loginWithEmailButton";
import LoginWithFacebook from "./components/socialLoginButtons/loginWithFacebook";
import LoginWithGoogle from "./components/socialLoginButtons/loginWithGoogle";
type SignInProps = {
  onHandleSignInClick: () => void;
  onCancel?: () => void;
};
const SignIn = ({ onHandleSignInClick, onCancel }: SignInProps) => {
  return (
    <div className="flex items-center flex-col justify-center space-y-3 w-3/4 mx-auto">
      {/* <LoginWithApple /> */}
      <LoginWithFacebook />
      <LoginWithGoogle onCancel={onCancel} />
      <LoginWithEmailButton onHandleClick={onHandleSignInClick} />
    </div>
  );
};

export default SignIn;
