import { useEffect, useState } from "react";

import { ExploreAllRoot } from "core/repository/search/types";
import { getExploreAllFromRepo } from "core/repository/search";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

const LIMIT = 24;
interface IExploreParams {
  search?: string;
  latitude?: string;
  longitude?: string;
  trailDifficulties?: string;
  rating?: string;
  minDuration?: string;
  maxDuration?: string;
  hasImage?: string;
  multiDay?: string;
}
const useGetExploreAll = () => {
  const locationParams = parse(useLocation().search) as IExploreParams;
  const [params, setParams] = useState({
    ...locationParams,
    limit: LIMIT,
    offset: 0,
  });

  useEffect(() => {
    setParams({
      search: locationParams.search,
      latitude: locationParams.latitude,
      longitude: locationParams.longitude,
      trailDifficulties: locationParams.trailDifficulties,
      limit: LIMIT,
      rating: locationParams.rating,
      minDuration: locationParams.minDuration,
      maxDuration: locationParams.maxDuration,
      hasImage: locationParams.hasImage,
      multiDay: locationParams.multiDay,
      offset: 0,
    });
  }, [
    locationParams.search,
    locationParams.latitude,
    locationParams.longitude,
    locationParams.trailDifficulties,
    locationParams.rating,
    locationParams.minDuration,
    locationParams.maxDuration,
    locationParams.hasImage,
    locationParams.multiDay,
  ]);

  const { data, isLoading, isError } = useQuery<ExploreAllRoot, Error>(
    ["exploreAll", params],
    async () => {
      const response = await getExploreAllFromRepo(params);
      return response.data;
    }
  );

  return {
    data,
    isLoading,
    isError,
    setParams,
    params,
  };
};

export default useGetExploreAll;
