import {
  getDestinationByIdFromRepo,
  getDestinationFromRepo,
} from "core/repository/destination";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ADMIN_ACTIVITY_TABS } from "admin/adminConstants";
import { ActivityPayload } from "core/repository/activity/types";
import { ActivityTabView } from "../types";
import AdminImageUploader from "admin/components/adminImageUploader";
import { Destination } from "core/repository/destination/types";
import FormFooter from "views/components/headstarttForm/formFooter";
import FormGroup from "views/components/headstarttForm/formGroup";
import { HeadstarttForm } from "views/components/headstarttForm";
import { HeadstarttSelect } from "views/components/headstarttSelect";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";
import { Spin } from "antd";
import StatusField from "./components/statusField";
import { errorToast } from "views/components/headstarttNotifications";

// "Provincial park", "Protected area", "Ecological reserve", "Recreation area"
const PARK_TYPES = [
  {
    label: "Provincial park",
    value: "Provincial park",
  },
  {
    label: "Protected area",
    value: "Protected area",
  },
  {
    label: "Ecological reserve",
    value: "Ecological reserve",
  },
  {
    label: "Recreation area",
    value: "Recreation area",
  },
];
const NameAndDescriptionForm = ({ payload, setPayload }: ActivityTabView) => {
  const [destById, setDestById] = useState<Destination | null>(null);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const handleSearch = useCallback((value: string) => {
    setLoading(true);
    setDestinations([]);
    getDestinationFromRepo(25, 0, value)
      .then((res) => {
        setDestinations(res.data.data.list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err.message);
      });
  }, []);
  const [form] = HeadstarttForm.useForm();
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = useCallback(
    async (values: ActivityPayload) => {
      setPayload({
        ...payload,
        ...values,
        orcs: Number(values.orcs),
        images: uploadedImages,
      });
      navigate({
        pathname: location.pathname,
        search: `?tab=${encodeURIComponent(ADMIN_ACTIVITY_TABS[1])}`,
      });
    },
    [payload, setPayload, uploadedImages, navigate, location.pathname]
  );

  useEffect(() => {
    const getDestinationById = async () => {
      if (payload?.destinationId) {
        const { data } = await getDestinationByIdFromRepo(
          payload.destinationId
        );
        if (data) {
          setDestById(data.data);
        }
        if (payload.images) {
          setUploadedImages(payload.images);
        }
      }
    };
    getDestinationById();
  }, [payload?.destinationId, form, payload]);

  return (
    <HeadstarttForm
      form={form}
      onFinish={handleSubmit}
      className="w-full space-y-8"
      initialValues={{
        title: payload?.title,
        description: payload?.description,
        destinationId: payload?.destinationId,
        status: payload?.status,
        images: payload?.images,
        additionalStatus: payload?.additionalStatus,
        orcs: payload?.orcs,
        category: payload?.category,
      }}
    >
      <FormFooter
        showCancelButton={false}
        showSaveButton={false}
        showBackButton={false}
      />
      <FormGroup
        label={"Title"}
        name={"title"}
        description="Enter a title"
        placeholder="Enter a title"
      />
      <FormGroup
        label={"Description"}
        name={"description"}
        description="Enter a description"
        placeholder="Enter a description"
        inputType="textarea"
      />
      <FormGroup
        label={"Category"}
        name={"category"}
        description="Enter a category"
        placeholder="Enter a category"
        inputType="children"
      >
        <HeadstarttSelect
          size="large"
          className="activity-select-small"
          placeholder="Select a category"
          value={payload?.category}
          onChange={(value) => {
            form.setFieldsValue({
              category: value,
            });
          }}
          options={PARK_TYPES}
        />
      </FormGroup>
      <FormGroup
        label={
          destById?.destinationName
            ? `Selected Destination - ${destById.destinationName}`
            : "Destination"
        }
        name={"destinationId"}
        description="Search for a destination"
        placeholder="Select a destination"
        inputType="children"
      >
        <SelectSearch
          size="large"
          showSearch
          className="activity-select-small"
          clearIcon
          filterOption={false}
          loading={loading}
          value={
            destinations.find(
              (destination) => destination._id === payload?.destinationId
            )?.destinationName
          }
          onChange={(value) => {
            form.setFieldsValue({
              destinationId: value,
            });
          }}
          onSearch={handleSearch}
          notFoundContent={loading ? <Spin size="small" /> : null}
          options={destinations.map((destination) => ({
            label: destination.destinationName,
            value: destination._id,
          }))}
        />
      </FormGroup>
      <FormGroup
        label={"ORCS"}
        name={"orcs"}
        description="Enter ORCS"
        placeholder="Enter ORCS"
        type="number"
      />
      <StatusField form={form} />
      <AdminImageUploader
        label={"Upload Images"}
        name={"images"}
        setUploadedImages={setUploadedImages}
        uploadedImages={uploadedImages}
        form={form}
      />
    </HeadstarttForm>
  );
};

export default NameAndDescriptionForm;
