import {
  ExploreAllRoot,
  RecentlyViewedPayload,
  RecentlyViewedRoot,
  SearchParams,
  SuggestionsRoot,
} from "./types";

import { apiRequest } from "core/services/apiServices";

export const getExploreAllFromRepo = (params: SearchParams) =>
  apiRequest.getByQueryParam<ExploreAllRoot>(
    "user/search/v1/searchAll/",
    params
  );

export const getSearchSuggestionsFromRepo = (params: SearchParams) =>
  apiRequest.getByQueryParam<SuggestionsRoot>(
    "/user/search/v1/suggestions/",
    params
  );

export const addRecentlyViewed = (payload: RecentlyViewedPayload) =>
  apiRequest.post("/user/search/v1/recentlyViewed", payload);

export const getRecentlyViewed = (params: SearchParams) =>
  apiRequest.getByQueryParam<RecentlyViewedRoot>(
    "/user/search/v1/recentlyViewedList",
    params
  );

export const removeRecentlyViewed = () =>
  apiRequest.deleteWithoutId("/user/search/v1/recentlyViewed");
