import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FilterHeader from "./filterHeader";
import { HeadstarttRadio } from "../headstarttRadio";
import { RadioChangeEvent } from "antd";

const HAS_IMAGE = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];

const HasImageFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const hasImage = searchParams.get("hasImage");
  const [value, setValue] = useState(hasImage || "");
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value) {
      searchParams.set("hasImage", value);
    }
    if (!value) {
      searchParams.delete("hasImage");
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [value, location.pathname, navigate, location.search, searchParams]);

  return (
    <div>
      <FilterHeader
        heading={"Has image"}
        handleClear={() => {
          setValue("");
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {HAS_IMAGE.map((has) => {
            return (
              <div key={has.value} className="col-span-1">
                <HeadstarttRadio value={has.value}>{has.label}</HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
    </div>
  );
};

export default HasImageFilter;
