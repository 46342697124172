import CategoryFilter from "./categoryFilter";
import DifficultyFilter from "./difficultyFilter";
import DurationFilter from "./durationFilter";
import HasImageFilter from "./hasImageFilter";
import LocationFilter from "./locationFilter";
import MultidayFilter from "./multidayFilter";
import PriceFilter from "./priceFilter";
import RatingsFilter from "./ratingsFilter";
import ResetFilter from "./resetFilter";
import ShowMoreFiltersCTA from "./showMoreFiltersCTA";

<div className="border my-4" />;
const Divider = () => <div className="border my-4" />;

const Filters = () => {
  return (
    <div>
      <LocationFilter />
      <Divider />
      <DifficultyFilter />
      <Divider />
      <DurationFilter />
      <Divider />
      <PriceFilter />
      <Divider />
      <CategoryFilter />
      <Divider />
      <RatingsFilter />
      <Divider />
      <MultidayFilter />
      <Divider />
      <HasImageFilter />
      <Divider />
      <ShowMoreFiltersCTA />
      <div className="flex items-center justify-center my-4">
        <ResetFilter />
      </div>
    </div>
  );
};

export default Filters;
