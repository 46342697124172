import { useCallback, useState } from "react";

import ErrorCard from "views/components/errorCard";
import { HeadstarttRating } from "views/components/headstarttRating";
import { Link } from "react-router-dom";
import ListWithMaps from "views/layouts/listWithMaps";
import ListWithMapsModal from "views/layouts/listWithMaps/listWithMapsModal";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { MarkerProps } from "views/layouts/listWithMaps/types";
import useGetActivityList from "core/services/activity/useGetActivityList";

const LandingActivityMapView = ({
  visible,
  handleCancel,
}: {
  visible: boolean;
  handleCancel: () => void;
}) => {
  const { data, isLoading, isError, offset } = useGetActivityList(false);
  const [page, setPage] = useState(offset + 1);

  const handlePagination = useCallback((p: number) => {
    setPage(p);
  }, []);
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data) return <>Data Not Available</>;
  const markers: MarkerProps[] =
    data?.data.list.map((activity) => ({
      position: [
        activity.locationDetail.latitude,
        activity.locationDetail.longitude,
      ],
      label: activity.title,
    })) || [];

  return (
    <ListWithMapsModal visible={visible}>
      <ListWithMaps
        page={page}
        totalCount={data.data.total}
        onHandleCancel={handleCancel}
        handlePagination={handlePagination}
        markers={markers}
      >
        <div className="py-8">
          {data.data.list.map((item) => (
            <div
              className="hover:bg-green-100 transition ease-in-out delay-150 br-4 py-1"
              key={item._id}
            >
              <Link to={`/discover-activities/${item._id}`}>
                <div className="flex gap-4">
                  <img
                    src={item.images[0]}
                    alt={item.title}
                    className="h-12 w-12 rounded object-cover"
                  />
                  <div>
                    <p className="fs-16 font-medium mb-0 text-black">
                      {item.title}
                    </p>
                    <div className="flex gap-2 items-center text-black fs-12">
                      <HeadstarttRating
                        className="small-rating"
                        disabled
                        value={item.averageRating}
                      />
                      <p className="fs-12 font-medium mb-0">
                        ({item.totalReviews})
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </ListWithMaps>
    </ListWithMapsModal>
  );
};

export default LandingActivityMapView;
