import { ACTIVITY_ENDPOINTS } from "./endpoints";
import { ActivityRootObject } from "./types";
import { apiRequest } from "core/services/apiServices";

export const getActivitiesFromRepo = (
  limit: number,
  offset: number,
  params?: Record<string, string>
) =>
  apiRequest.getByQueryParam<ActivityRootObject>(ACTIVITY_ENDPOINTS.getList, {
    limit,
    offset,
    ...params,
  });
