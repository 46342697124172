import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

import ActivityList from "./activityList";
import { ActivityRootObject } from "core/repository/activity/types";
import HeadStarttTabsGroup from "views/components/headstarttTabs/headstarttTabsGroup";
import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttRating } from "views/components/headstarttRating";
import ListWithMaps from "views/layouts/listWithMaps";
import ListWithMapsModal from "views/layouts/listWithMaps/listWithMapsModal";
import Loader from "views/components/loader";
import { Map } from "views/components/icons";
import { MarkerProps } from "views/layouts/listWithMaps/types";
import PopularHeadingGroup from "views/components/typography/popularHeading";
import { getActivitiesFromRepo } from "core/repository/activity/getActivitiesFromRepo";
import useGetActivityType from "core/services/home/useGetActivityType";
import { useQuery } from "react-query";

export const ActivityListCTA = ({
  handleExploreClick,
  handleMapClick,
  totalCount,
}: {
  handleExploreClick: () => void;
  handleMapClick: () => void;
  totalCount: number;
}) => {
  return (
    <div className="flex flex-wrap items-center justify-center gap-2 my-12">
      <HeadstarttButton type="ghost" onClick={handleExploreClick}>
        <span>Show more activity ({totalCount}+)</span>
      </HeadstarttButton>
      <HeadstarttButton onClick={handleMapClick}>
        <div className="flex gap-2 items-center justify-center">
          <Map height={24} />
          Map
        </div>
      </HeadstarttButton>
    </div>
  );
};
const LandingActivityGroup = () => {
  const [page, setPage] = useState(1);

  const [activityParams, setActivityParams] = useState<
    Record<string, string> | undefined
  >(undefined);
  const { data, isLoading } = useQuery<ActivityRootObject, Error>(
    ["activities", page, activityParams],
    async () => {
      const { data: responseData } = await getActivitiesFromRepo(
        8,
        page - 1,
        activityParams
      );
      return responseData;
    }
  );
  const { data: activityTypes, isLoading: activityTypeLoader } =
    useGetActivityType();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handlePagination = useCallback((p: number) => {
    setPage(p);
  }, []);
  const handleExploreClick = useCallback((type: string[]) => {
    const params = type.reduce((curr, acc, index) => {
      return { ...curr, [`activityTypes[${index}]`]: acc };
    }, {});
    setActivityParams(params);
  }, []);
  const markers: MarkerProps[] =
    data?.data.list.map((activity) => ({
      position: [
        activity.locationDetail.latitude,
        activity.locationDetail.longitude,
      ],
      label: activity.title,
    })) || [];
  const handleCardClick = useCallback(
    (id: string) => navigate(`/discover-activities/${id}`),
    [navigate]
  );
  return (
    <div>
      {data?.data.list && (
        <ListWithMapsModal visible={visible}>
          <ListWithMaps
            page={page}
            totalCount={data.data.total}
            onHandleCancel={handleCancel}
            handlePagination={handlePagination}
            markers={markers}
          >
            <div className="py-8">
              {data.data.list.map((activity) => (
                <div
                  className="hover:bg-green-100 transition ease-in-out delay-150 br-4 py-1"
                  key={activity._id}
                >
                  <Link to={`/discover-activities/${activity._id}`}>
                    <div className="flex gap-4">
                      <img
                        src={activity.images[0]}
                        alt={activity.title}
                        className="h-12 w-12 rounded object-cover"
                      />
                      <div>
                        <p className="fs-16 font-medium mb-0 text-black">
                          {activity.title}
                        </p>
                        <div className="flex gap-2 items-center text-black fs-12">
                          <HeadstarttRating
                            className="small-rating"
                            disabled
                            value={activity.averageRating}
                          />
                          <p className="fs-12 font-medium mb-0">
                            ({activity.totalReviews})
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </ListWithMaps>
        </ListWithMapsModal>
      )}

      <PopularHeadingGroup
        text="Have you tried popular tours by yourself?"
        headingPartTwo="Activity"
      />
      {activityTypeLoader
        ? "Loading..."
        : activityTypes?.data?.activityTypes && (
            <HeadStarttTabsGroup
              handleTabClick={handleExploreClick}
              multiple
              tabs={activityTypes?.data.activityTypes}
            />
          )}
      {isLoading ? (
        <Loader />
      ) : data?.data.total ? (
        <ActivityList
          activities={data.data.list.slice(0, 3)}
          handleClick={handleCardClick}
        />
      ) : (
        "No activity found"
      )}
      <ActivityListCTA
        totalCount={data?.data.total ?? 0}
        handleMapClick={() => setVisible(true)}
        handleExploreClick={() => navigate("/explore/activities")}
      />
    </div>
  );
};

export default LandingActivityGroup;
