import { ChevronDown, ChevronUp } from "views/components/icons";
import { HeadstarttCollapse, Panel } from "views/components/headstarttCollapse";

import ExploreSidebarMap from "./exploreSidebarMap";
import Filters from "views/components/filters";
import { MarkerProps } from "../listWithMaps/types";
import { useState } from "react";

const ExploreSidebar = ({ markers }: { markers: MarkerProps[] }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState<string[] | string>([
    "1",
  ]);

  return (
    <div className="space-y-8">
      {!!markers?.length && (
        <div className="border h-60 br-16 bg-white">
          <ExploreSidebarMap markers={markers} />
        </div>
      )}
      <div className="bg-white br-16 ">
        <HeadstarttCollapse
          onChange={(isOpen) => {
            setIsCollapseOpen(isOpen);
          }}
          defaultActiveKey={["1"]}
          className="m-0"
          bordered={true}
          ghost={true}
        >
          <Panel
            showArrow={false}
            key={"1"}
            header={
              <div
                className={`flex justify-between items-center w-full p-4 font-medium fs-16 select-none ${
                  isCollapseOpen.length
                    ? "bg-primary text-white br-t-r-16 br-t-l-16"
                    : "bg-white border-primary border text-primary br-16"
                }`}
              >
                Filter
                {isCollapseOpen.length ? (
                  <ChevronUp height={24} className="text-white" />
                ) : (
                  <ChevronDown height={24} className="text-primary" />
                )}
              </div>
            }
          >
            <div className="bg-white select-none ">
              <Filters />
            </div>
          </Panel>
        </HeadstarttCollapse>
      </div>
      <div className="py-24" />
    </div>
  );
};

export default ExploreSidebar;
