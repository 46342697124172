import { useLocation, useNavigate } from "react-router-dom";

import { EventListRoot } from "core/repository/event/types";
import { getEventsFromRepo } from "core/repository/event";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;
const useGetEventList = (redirectToPageParam = true, enabled = true) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = parse(location.search);
  const search = queryParams.search ?? "";
  const destinationId = queryParams.destinationId ?? "";
  const pageFromUrl = queryParams.page ?? "1";
  const offset = Number(pageFromUrl);

  const { data, isLoading, isError, refetch } = useQuery<EventListRoot, Error>(
    ["events", offset, search, destinationId],
    async () => {
      const { data: responseData } = await getEventsFromRepo(
        LIMIT,
        offset - 1,
        {
          search: search as string,
          destinationId: destinationId as string,
        }
      );
      return responseData;
    },
    {
      enabled,
    }
  );

  useEffect(() => {
    if (redirectToPageParam) {
      navigate({
        pathname: location.pathname,
        search: `?page=${offset}`,
      });
    }
  }, [
    location.pathname,
    navigate,
    redirectToPageParam,
    offset,
    search,
    destinationId,
  ]);

  return {
    data,
    isLoading,
    isError,
    offset,
    refetch,
  };
};
export default useGetEventList;
