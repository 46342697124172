import { useLocation, useNavigate } from "react-router-dom";

import { DestinationsRoot } from "core/repository/destination/types";
import { getDestinationFromRepo } from "core/repository/destination";
import queryString from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 10;
const useGetDestination = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const offset = Number(params.page) || 1;
  const search = params.search || "";
  const navigate = useNavigate();

  const { data, status, refetch } = useQuery<DestinationsRoot, Error>(
    ["destinations", offset, search],
    async () => {
      const { data: responseData } = await getDestinationFromRepo(
        LIMIT,
        offset - 1,
        search as string
      );
      return responseData;
    }
  );

  useEffect(() => {
    navigate({
      pathname: location.pathname,
      search: `?page=${offset}&search=${search}`,
    });
  }, [navigate, offset, location.pathname, search]);

  return {
    data,
    isLoading: status === "loading",
    isError: status === "error",
    page: offset,
    search,
    refetch,
  };
};

export default useGetDestination;
