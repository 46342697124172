import { INamedRoute } from "../types";
import SEOWrapper from "./seoWrapper";

const NamedRoute = ({
  title,
  description,
  keywords,
  children,
}: INamedRoute) => {
  return (
    <>
      <SEOWrapper
        title={title}
        description={description || ""}
        keywords={keywords}
      />
      <>{children}</>
    </>
  );
};

export default NamedRoute;
