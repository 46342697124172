import { useLocation, useNavigate } from "react-router-dom";

import { ActivityRootObject } from "core/repository/activity/types";
import { getActivitiesFromRepo } from "core/repository/activity/getActivitiesFromRepo";
import { parse } from "query-string";
import { useEffect } from "react";
import { useQuery } from "react-query";

const LIMIT = 12;
const useGetActivityList = (redirectToPageParam = true, enabled = true) => {
  const location = useLocation();
  const queryParams = parse(location.search);
  const pageFromUrl = queryParams.page ?? "1";
  const search = queryParams.search ?? "";
  const destinationId = queryParams.destinationId ?? "";
  const offset = Number(pageFromUrl);
  const navigate = useNavigate();

  const { data, status, refetch } = useQuery<ActivityRootObject, Error>(
    ["activities", offset, search, destinationId],
    async () => {
      const { data: responseData } = await getActivitiesFromRepo(
        LIMIT,
        offset - 1,
        {
          destinationId: destinationId as string,
          search: search as string,
        }
      );
      return responseData;
    },
    {
      enabled,
    }
  );

  useEffect(() => {
    if (redirectToPageParam) {
      navigate({
        pathname: location.pathname,
        search: `?page=${offset}`,
      });
    }
  }, [navigate, offset, location.pathname, redirectToPageParam]);

  return {
    data,
    isLoading: status === "loading",
    isError: status === "error",
    offset,
    refetch,
  };
};

export default useGetActivityList;
