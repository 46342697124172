import { EventListRoot, EventPayload, EventRoot } from "./types";

import { apiRequest } from "core/services/apiServices";

export const getEventsFromRepo = (
  limit: number,
  offset: number,
  params?: Record<string, string>
) =>
  apiRequest.getByQueryParam<EventListRoot>("/user/event/v1/list", {
    limit,
    offset,
    ...params,
  });

export const getEventByIdFromRepo = (id: string) =>
  apiRequest.getByQueryParam<EventRoot>("/user/event/v1", {
    eventId: id,
  });

export const updateEventToRepo = (payload: EventPayload) =>
  apiRequest.patch("/admin/event/v1", payload);

export const refreshEventsFromRepo = () =>
  apiRequest.post("/admin/event/v1/refreshEvents");
