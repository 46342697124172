import { HeadstarttModal } from "views/components/headstarttModal";
import LoginContainer from "./loginContainer";

export type SignInModalProps = {
  visible: boolean;
  onCancel: () => void;
  onHandleSignUpClick: () => void;
};

const SignInModal = ({
  visible,
  onCancel,
  onHandleSignUpClick,
}: SignInModalProps) => {
  return (
    <HeadstarttModal
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(256, 256, 256, 0.95)",
      }}
      className="headstartt-antd-modal"
      width={672}
      visible={visible}
      closable={false}
      onCancel={onCancel}
    >
      <LoginContainer
        onCancel={onCancel}
        onHandleSignUpClick={onHandleSignUpClick}
      />
    </HeadstarttModal>
  );
};
export default SignInModal;
