import { ChevronDown } from "../icons";
import FilterHeader from "./filterHeader";
import { HeadstarttInput } from "../headstarttInput";
import { HeadstarttSelect } from "../headstarttSelect";

const PriceFilter = () => {
  return (
    <div>
      <FilterHeader heading={"Price type"} handleClear={() => {}} />
      <HeadstarttSelect
        suffixIcon={<ChevronDown height={18} />}
        placeholder="Select"
        style={{ width: "100%", borderRadius: "8px", marginBottom: "8px" }}
      >
        <option value="">Any</option>
        <option value="">Free</option>
      </HeadstarttSelect>
      <div className="grid grid-cols-2 gap-1 items-center justify-center mb-4">
        <div className="col-span-1">
          <HeadstarttInput
            size="middle"
            placeholder="Min. price"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
        <div className="col-span-1">
          <HeadstarttInput
            size="middle"
            placeholder="Max. price"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              borderRadius: "4px",
              border: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceFilter;
