import { useLocation, useParams } from "react-router-dom";

import ExploreActivities from "./exploreActivities";
import ExploreAll from "./exploreAll/exploreAll";
import ExploreCover from "./exploreCover";
import ExploreDestination from "./exploreDestination";
import ExploreEvents from "./exploreEvents";
import ExploreProjects from "./exploreProjects";
import HeadstarttContainer from "views/components/headstarttContainer";
import queryString from "query-string";

const Explore = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const params = useParams();
  const path = params.path;

  const getViews = () => {
    switch (path) {
      case "all":
        return <ExploreAll />;
      case "destination":
        return <ExploreDestination />;
      case "activities":
        return <ExploreActivities />;
      case "events":
        return <ExploreEvents />;
      case "projects":
        return <ExploreProjects />;
      default:
        return <ExploreAll />;
    }
  };
  const selectedPath = params.path as string;
  const search = queryParams.q as string;
  return (
    <>
      <div className="pt-24 pb-8 fixed w-full bg-white z-30">
        <HeadstarttContainer>
          <ExploreCover path={selectedPath} search={search} />
        </HeadstarttContainer>
      </div>
      <div className="bg-rainee pt-64 h-full min-h-screen">
        <HeadstarttContainer>
          <div className="py-12">{getViews()}</div>
        </HeadstarttContainer>
      </div>
    </>
  );
};

export default Explore;
