import ErrorCard from "views/components/errorCard";
import HeadstarttContainer from "views/components/headstarttContainer";
import LandingActivityGroup from "./components/landingActivityGroup";
import LandingCover from "./components/landingCover";
import LandingEventGroup from "./components/landingEventGroup";
import LandingProjectGroup from "./components/landingProjectGroup";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import useGetHomeData from "core/services/home/useGetHomeData";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { homeData, isLoading, isError } = useGetHomeData();
  const navigate = useNavigate();
  if (process.env.REACT_APP_ENV === "admin") {
    document.title = "Admin Panel";
    navigate("/admin");
  }
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  return (
    <>
      {homeData?.data && (
        <LandingCover
          popularActivities={homeData.data.popularActivitiesList.list}
        />
      )}
      <div className="my-24">
        <HeadstarttContainer>
          <div className="space-y-24">
            <LandingActivityGroup />
            <LandingEventGroup />
            {homeData?.data?.projectsList?.total && (
              <LandingProjectGroup
                popularProjects={homeData?.data.projectsList.list}
              />
            )}
          </div>
        </HeadstarttContainer>
      </div>
    </>
  );
};

export default Home;
