import AdvisoryList from "./advisoryList";
import { Alert } from "../icons";
import { HeadstarttModal } from "../headstarttModal";
import HeadstarttReadMoreLess from "../headstarttReadMoreLess";
import { HeadstarttSmallButton } from "../headstarttButton";
import { ImportantNotice } from "core/repository/activity/types";
import { useLocalStorage } from "core/hooks/useLocalStorage";
import { useState } from "react";

const AdvisoryCard = ({
  importantNotice,
  id,
}: {
  importantNotice: ImportantNotice[];
  id: string;
}) => {
  const [show, setShow] = useState(true);
  const [showMore, setShowMore] = useLocalStorage(`${id}_show_more`, true);
  const [readMore, setReadMore] = useState(false);
  if (!show || !showMore) return <></>;
  const firstNotice = importantNotice?.[0];
  if (!firstNotice) return <></>;
  return (
    <div className="p-4 bg-[#fce9e4] br-16 mb-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <Alert />
          <h1 className="fs-24 font-medium mb-0">
            {firstNotice?.title || "Important Notice"}
          </h1>
        </div>
        <HeadstarttSmallButton
          onClick={() => setShow(!show)}
          newstyle={{
            backgroundColor: "#FFF",
            color: "#EE7654",
            borderColor: "#EE7654",
          }}
        >
          Close
        </HeadstarttSmallButton>
      </div>
      <div className="fs-14 my-4">
        <HeadstarttReadMoreLess text={firstNotice?.description} />
      </div>
      <div className="flex items-center justify-between">
        <HeadstarttSmallButton
          onClick={() => setShowMore(!showMore)}
          newstyle={{
            backgroundColor: "#f5f5f5",
            color: "#EE7654",
            borderColor: "#EE7654",
          }}
        >
          Don&apos;t show again
        </HeadstarttSmallButton>
        <HeadstarttSmallButton
          onClick={() => setReadMore(true)}
          newstyle={{
            backgroundColor: "#FFF",
            color: "#000",
            borderColor: "#000",
          }}
        >
          Show More Advisory
        </HeadstarttSmallButton>
      </div>

      <HeadstarttModal
        visible={readMore}
        footer={null}
        closable={false}
        width={600}
        centered
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="bg-white br-16 p-6">
          <AdvisoryList importantNotice={importantNotice} />
          <div className="flex justify-between items-center space-y-4 z-50">
            <p className="fs-10 mb-0 text-gray-400">
              All advisories are updated every 24 hours.
            </p>
            <HeadstarttSmallButton
              onClick={() => setReadMore(false)}
              newstyle={{
                backgroundColor: "#FFF",
                color: "#EE7654",
                borderColor: "#EE7654",
              }}
            >
              Close
            </HeadstarttSmallButton>
          </div>
        </div>
      </HeadstarttModal>
    </div>
  );
};

export default AdvisoryCard;
