import { Alert } from "../icons";
import HeadstarttReadMoreLess from "../headstarttReadMoreLess";
import { ImportantNotice } from "core/repository/activity/types";

const AdvisoryList = ({
  importantNotice = [],
}: {
  importantNotice: ImportantNotice[];
}) => {
  return (
    <div className="space-y-4 h-128 overflow-scroll">
      {importantNotice.map((notice, i) => (
        <div key={i}>
          <div className="flex gap-2 items-center">
            <Alert />
            <h1 className="fs-24 font-medium mb-0">{notice.title}</h1>
          </div>
          <HeadstarttReadMoreLess text={notice.description} />
        </div>
      ))}
    </div>
  );
};

export default AdvisoryList;
