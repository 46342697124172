import {
  HeadstarttRadio,
  RadioChangeEvent,
} from "views/components/headstarttRadio";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FilterHeader from "./filterHeader";

const RATINGS = [
  {
    label: "1+ Stars",
    value: "1",
  },
  {
    label: "2+ Stars",
    value: "2",
  },
  {
    label: "3+ Stars",
    value: "3",
  },
  {
    label: "4+ Stars",
    value: "4",
  },
  {
    label: "5+ Stars",
    value: "5",
  },
];

const RatingsFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const rating = searchParams.get("rating");

  const [value, setValue] = useState(rating || "");
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setValue(e.target.value);
      if (e.target.value) {
        searchParams.set("rating", e.target.value);
      } else {
        searchParams.delete("rating");
      }
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location.pathname, navigate, searchParams]
  );

  return (
    <div>
      <FilterHeader
        heading={"Ratings"}
        handleClear={() => {
          setValue("");
          searchParams.delete("rating");
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {RATINGS.map((rate) => {
            return (
              <div key={rate.value} className="col-span-1">
                <HeadstarttRadio value={rate.value}>
                  {rate.label}
                </HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
    </div>
  );
};

export default RatingsFilter;
