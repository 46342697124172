import "./index.scss";

import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const helmetContext = {};
const queryClient = new QueryClient();

root.render(
  <HelmetProvider context={helmetContext}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
