import { Cross } from "views/components/icons";
import { HeadstarttButton } from "views/components/headstarttButton";
import { HeadstarttModal } from "views/components/headstarttModal";
import { UploadFilesResponseRoot } from "core/repository/uploadFiles/types";
import UploadPhotosSection from "./uploadPhotosSection";
type UploadPhotosModalProps = {
  visible: boolean;
  onCancel: () => void;
  onSuccess: (res: UploadFilesResponseRoot["data"]) => void;
};
const UploadPhotosModal = ({
  visible,
  onCancel,
  onSuccess,
}: UploadPhotosModalProps) => {
  return (
    <HeadstarttModal
      closable={false}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      maskStyle={{
        backgroundColor: "rgba(256, 256, 256, 0.95)",
      }}
      className="headstartt-antd-modal"
      width={600}
    >
      <div className="border br-8 p-4 bg-white drop-shadow-2xl">
        <div className="flex items-center justify-between mb-8">
          <p className="fs-24 mb-0">Upload Photos</p>
          <div
            className="bg-gray-100 p-2 br-4 cursor-pointer"
            onClick={onCancel}
          >
            <Cross height={24} />
          </div>
        </div>
        <UploadPhotosSection onSuccess={onSuccess} />
        <div className="my-2 flex justify-end">
          <HeadstarttButton
            onClick={onCancel}
            size="small"
            style={{
              borderRadius: "4rem",
              border: `1px solid #006653`,
              background: "#006653",
              padding: "4px 16px",
              fontSize: "16px",
              fontWeight: "medium",
              color: "#fff",
              height: "fit-content",
            }}
          >
            Continue
          </HeadstarttButton>
        </div>
      </div>
    </HeadstarttModal>
  );
};

export default UploadPhotosModal;
