import { useCallback, useState } from "react";

import { Activity } from "core/repository/activity/types";
import { EventData } from "core/repository/event/types";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";
import { getActivitiesFromRepo } from "core/repository/activity/getActivitiesFromRepo";
import { getEventsFromRepo } from "core/repository/event";

const ActivitiesAndEventsForDestination = ({ id }: { id: string }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [events, setEvents] = useState<EventData[]>([]);
  const [activityValue, setActivityValue] = useState<string[]>([]);
  const [eventValue, setEventValue] = useState<string[]>([]);
  const handleSearchForActivities = useCallback(
    async (value: string) => {
      const { data } = await getActivitiesFromRepo(25, 0, {
        destinationId: id,
        search: value,
      });
      setActivities(data.data.list);
    },
    [id]
  );
  const handleSearchForEvents = useCallback(
    async (value: string) => {
      const { data } = await getEventsFromRepo(25, 0, {
        destinationId: id,
        search: value,
      });
      setEvents(data.data.list);
    },
    [id]
  );
  if (!id) return <></>;
  return (
    <div className="my-8">
      <h1 className="fs-18 font-light  border-b mb-4">Activities and Events</h1>
      <div className="space-y-8">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4">
            <FormLabelWithDescription
              label={"Search activities"}
              description={"Search associated activities"}
            />
          </div>
          <div className="col-span-6">
            <SelectSearch
              showSearch
              onSearch={handleSearchForActivities}
              options={activities.map((activity) => ({
                label: activity.title,
                value: activity._id,
              }))}
              onChange={(value) => setActivityValue(value)}
              value={activityValue}
              className="activity-select-small"
              style={{
                width: "100%",
              }}
              size="large"
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4">
            <FormLabelWithDescription
              label={"Search events"}
              description={"Search associated events"}
            />
          </div>
          <div className="col-span-6">
            <SelectSearch
              showSearch
              onSearch={handleSearchForEvents}
              className="activity-select-small"
              options={events.map((event) => ({
                label: event.title,
                value: event._id,
              }))}
              style={{
                width: "100%",
              }}
              value={eventValue}
              onChange={(value) => setEventValue(value)}
              size="large"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesAndEventsForDestination;
