import { useLocation, useNavigate } from "react-router-dom";

import DestinationCard from "views/components/headstarttCard/destinationCard";
import ErrorCard from "views/components/errorCard";
import HeadstarttPagination from "views/components/headstarttPagination";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { Location } from "views/components/icons";
import { useCallback } from "react";
import useGetDestination from "core/services/destination/useGetDestination";

const ExploreDestination = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading, isError, page, search } = useGetDestination();
  const handleChangePage = useCallback(
    (p: number) => {
      navigate(
        `${location.pathname}?page=${[p]}${search ? `&search=${search}` : ""}`
      );
    },
    [location.pathname, navigate, search]
  );
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;

  return (
    <>
      <div className="p-8 bg-white br-16">
        <div className="flex gap-2 items-center mb-8">
          <div className="bg-gray-100 rounded-full h-12 w-12 flex items-center justify-center">
            <Location height={24} />
          </div>
          <h2 className="fs-32 font-medium mb-0">Destinations</h2>
        </div>
        <div className="space-y-8">
          {data?.data.list.map((destination) => (
            <DestinationCard key={destination._id} destination={destination} />
          ))}
        </div>
      </div>
      <div className="flex justify-center bg-white my-4 p-3 br-16">
        <HeadstarttPagination
          total={data?.data.total}
          current={Number(page)}
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default ExploreDestination;
