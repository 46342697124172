import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

type HeadstarttSegmentMenuProps = {
  segmentMenu: {
    title: string;
    path: string;
  }[];
  selected?: string;
};

const HeadstarttSegmentMenu = ({
  segmentMenu,
  selected,
}: HeadstarttSegmentMenuProps) => {
  const navigate = useNavigate();
  const [selectedSegment, setSelectedSegment] = useState(
    selected || segmentMenu[0].path
  );
  useEffect(() => {
    setSelectedSegment(selected || segmentMenu[0].path);
  }, [selected, segmentMenu]);
  const handleClick = (path: string) => {
    setSelectedSegment(path);
    navigate(path);
  };
  return (
    <div className="flex flex-wrap items-center justify-center divide-x text-center border rounded-full w-fit mx-auto">
      {segmentMenu.map(({ title, path }) => (
        <button
          onClick={() => handleClick(path)}
          key={path}
          className={`${
            selectedSegment === path
              ? "bg-primary text-white"
              : "bg-white text-gray-800"
          } fs-20 p-3 px-8 first:rounded-l-full last:rounded-r-full`}
        >
          <span className="text-center">{title}</span>
        </button>
      ))}
    </div>
  );
};

export default HeadstarttSegmentMenu;
