import { useCallback, useState } from "react";

import { EventData } from "core/repository/event/types";
import FormLabelWithDescription from "views/components/headstarttForm/formLabelWithDescription";
import { SelectSearch } from "views/components/headstarttSearch/selectSearch";
import { getEventsFromRepo } from "core/repository/event";

const AssignedEventsForHost = ({ id }: { id: string }) => {
  const [events, setEvents] = useState<EventData[]>([]);
  const [eventValue, setEventValue] = useState<string[]>([]);

  const handleSearchForEvents = useCallback(
    async (value: string) => {
      const { data } = await getEventsFromRepo(25, 0, {
        hostID: id,
        search: value,
      });
      setEvents(data.data.list);
    },
    [id]
  );
  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-4">
          <FormLabelWithDescription
            label={"Search events"}
            description={"Search associated events"}
          />
        </div>
        <div className="col-span-6">
          <SelectSearch
            showSearch
            onSearch={handleSearchForEvents}
            options={events.map((event) => ({
              label: event.title,
              value: event._id,
            }))}
            onChange={(value) => setEventValue(value)}
            value={eventValue}
            className="activity-select-small"
            style={{
              width: "100%",
            }}
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default AssignedEventsForHost;
