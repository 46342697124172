import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FilterHeader from "./filterHeader";
import { HeadstarttRadio } from "../headstarttRadio";
import { RadioChangeEvent } from "antd";

const DAYS = [
  {
    label: "1 Day",
    value: "1",
  },
  {
    label: "2 Days",
    value: "2",
  },
  {
    label: "3+ Days",
    value: "3m",
  },
];

const MultidayFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const multiDay = searchParams.get("multiDay");

  const [value, setValue] = useState(multiDay || "");
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      setValue(e.target.value);
      if (e.target.value) {
        searchParams.set("multiDay", e.target.value);
      } else {
        searchParams.delete("multiDay");
      }
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    },
    [location.pathname, navigate, searchParams]
  );

  return (
    <div>
      <FilterHeader
        heading={"Multiday"}
        handleClear={() => {
          setValue("");
          searchParams.delete("multiDay");
          navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
          });
        }}
      />
      <HeadstarttRadio.Group onChange={onChange} value={value}>
        <div className="grid grid-cols-2 space-y-2 items-end">
          {DAYS.map((day) => {
            return (
              <div key={day.value} className="col-span-1">
                <HeadstarttRadio value={day.value}>{day.label}</HeadstarttRadio>
              </div>
            );
          })}
        </div>
      </HeadstarttRadio.Group>
    </div>
  );
};

export default MultidayFilter;
