import { useCallback, useState } from "react";

import ErrorCard from "views/components/errorCard";
import EventList from "./eventList";
import { HeadstarttButton } from "views/components/headstarttButton";
import LandingEventMapView from "./landingEventMapView";
import LoaderFullScreen from "views/components/loader/loaderFullScreen";
import { Map } from "views/components/icons";
import PopularHeadingGroup from "views/components/typography/popularHeading";
import useGetEventList from "core/services/event/useGetEventList";

export const EventListCTA = ({
  handleMapClick,
}: {
  handleMapClick: () => void;
}) => (
  <div className="flex flex-wrap items-center justify-center gap-2 my-12">
    <HeadstarttButton onClick={handleMapClick}>
      <div className="flex items-center justify-center px-24">
        <Map height={24} />
        Map
      </div>
    </HeadstarttButton>
  </div>
);
const LandingEventGroup = () => {
  const { data, isLoading, isError } = useGetEventList(false);
  const [visible, setVisible] = useState(false);
  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);
  if (isLoading) return <LoaderFullScreen />;
  if (isError) return <ErrorCard />;
  if (!data?.data) return <>Data Not Available</>;
  const list = data.data.list;

  return (
    <div>
      <LandingEventMapView
        visible={false}
        handleCancel={function (): void {
          throw new Error("Function not implemented.");
        }}
      />
      <PopularHeadingGroup
        text="Want to try out more experiences near you?"
        headingPartTwo="Events"
      />
      <EventList events={list} />
      <LandingEventMapView visible={visible} handleCancel={handleCancel} />
      <EventListCTA handleMapClick={() => setVisible(true)} />
    </div>
  );
};

export default LandingEventGroup;
